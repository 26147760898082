var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
      }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
        return true;
      }
      var agent = window.navigator.userAgent.toLowerCase();
      var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;
      if(ipad == true){
        return true;
      }
      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}
